<template>
    <div class="input-wrapper">
      <input
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :required="required"
      />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SigmaInput',
    props: {
      type: {
        type: String,
        default: 'text'
      },
      modelValue: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      }
    }
  };
  </script>
  <style src="./SigmaInput.css"></style>
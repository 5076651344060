<template>
  <SigmaLink :href="'collections/' + collection.id + '/series'">
    <div class="collection" :style="{ backgroundColor: collection.color }">
      <img :src="require('@/assets/images/collections/' + collection.logo)" :alt="collection.name" />
    </div>
  </SigmaLink>
</template>
  
  <script>
import SigmaLink from '@/components/Basic/SigmaLink/SigmaLink.vue';

  export default {
    name: 'CollectionItem',
    components: {
        SigmaLink
    },
    props: {
      collection: {
        type: Object,
        required: true
      }
    }
  };
  </script>
    <style src="./CollectionItem.css"></style>
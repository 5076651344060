<template>
    <main id="registration-page">
      <RegistrationForm />
    </main>
  </template>
  
  <script>
import RegistrationForm from '@/components/Auth/RegistrationForm/RegistrationForm.vue';
  
  export default {
    name: 'RegistrationPage',
    components: {
        RegistrationForm
    }
  }
  </script>
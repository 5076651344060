<template>
    <button :type="type" :class="['sigma-button']" @click="handleClick">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    name: 'SigmaButton',
    props: {
      type: {
        type: String,
        default: 'button'
      },
    },
    methods: {
      handleClick(event) {
        this.$emit('click', event);
      }
    }
  };
  </script>
  <style src="./SigmaButton.css"></style>
import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'http://sigmacollection.ru/api',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export default apiClient;
<template>
    <div id="collections-list">
        <CollectionItem v-for="collection in collections" :key="collection.id" :collection="collection" />
    </div>
</template>
  
  <script>

    import CollectionItem from '@/components/Collections/CollectionItem/CollectionItem.vue';

  export default {
    name: 'CollecitonsList',
    props: {
        collections: {
            type: Array,
            required: true
        }
    },
    components: {
        CollectionItem
    }
  };
  </script>
  <style src="./CollectionsList.css"></style>
<!-- Login.vue -->
<template>
  <div id="login-form">
    <SigmaLogo />
      <form @submit.prevent="login">
        <SigmaInput
        v-model="email"
        type="email"
        placeholder="Email"
        required
      />
      <SigmaInput
        v-model="password"
        type="password"
        placeholder="Password"
        required
      />
      <div class="login-form__under">
        <SigmaLink href="https://www.example.com">Забыли пароль</SigmaLink>
        <SigmaLink href="/registration">Регистрация</SigmaLink>
      </div>
      <SigmaButton type="submit">Войти</SigmaButton>
      <SigmaError v-if="error">{{ error }}</SigmaError>
    </form>
  </div>
</template>

<script>
import apiClient from '@/services/api';
import SigmaInput from '@/components/FormComponents/SigmaInput/SigmaInput.vue';
import SigmaLink from '@/components/Basic/SigmaLink/SigmaLink.vue';
import SigmaButton from '@/components/Basic/SigmaButton/SigmaButton.vue';
import { handleApiError } from '@/services/errorHandler';
import SigmaError from '@/components/Basic/SigmaError/SigmaError.vue';
import SigmaLogo from '@/components/Basic/SigmaLogo/SigmaLogo.vue';

export default {
  name: 'LoginForm',
  components: {
    SigmaInput,
    SigmaLink,
    SigmaButton,
    SigmaError,
    SigmaLogo
  },
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await apiClient.post('/login', {
          email: this.email,
          password: this.password,
        });

        localStorage.setItem('token', response.data.user.api_token);
        this.$router.push('/profile');
        console.log('Login success:', response.data);
        // Дополнительная логика после успешной аутентификации, например, редирект на Dashboard
      } catch (error) {
        this.error = handleApiError(error, 'Пользователь не найден. Проверьте данные и попробуйте снова.');
        console.error('Login error:', error);
        // Обработка ошибок
      }
    },
  },
};
</script>
<style src="./LoginForm.css"></style>
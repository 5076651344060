<template>
    <svg class="exit-icon" width="550" height="618" viewBox="0 0 550 618" fill="none" xmlns="http://www.w3.org/2000/svg" @click="logout">
        <path d="M375 42.366H41.6666V509.033C41.6666 545.85 71.5143 575.7 108.333 575.7H375" stroke="black" stroke-width="83.3333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M408.333 409.033L508.333 309.033M508.333 309.033L408.333 209.033M508.333 309.033H175" stroke="black" stroke-width="83.3333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
  
  <script>
  export default {
    name: 'ExitIcon',
    methods: {
      logout() {
        // Удаление токена из localStorage
        localStorage.removeItem('token');
        // Перенаправление на страницу входа
        this.$router.push('/login');
      }
    }
  };
  </script>
  <style src="./ExitIcon.css"></style>
    
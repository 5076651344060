<template>
    <div id="registration-form">
      <SigmaLogo />
        <form @submit.prevent="registration">
            <SigmaInput
                v-model="nickname"
                type="text"
                placeholder="Никнейм"
                required
            />
            <SigmaInput
            v-model="email"
            type="email"
            placeholder="Email"
            required
            />
            <SigmaInput
            v-model="password"
            type="password"
            placeholder="Пароль"
            required
            />
            <SigmaInput
            v-model="password_confirmation"
            type="password"
            placeholder="Пароль еще раз"
            required
            />
            <div class="registration-form__under">
            <SigmaLink href="/login">У меня уже есть аккаунт</SigmaLink>
            </div>
            <SigmaButton type="submit">Регистрация</SigmaButton>
            <SigmaError v-if="error">{{ error }}</SigmaError>
            <div  v-if="success" class="registration-form__success">
                <SigmaSuccess>{{ success }}</SigmaSuccess>
                <SigmaLink href="/login">Войти</SigmaLink>
            </div>
        </form>
    </div>
  </template>
  
  <script>
  import apiClient from '@/services/api';
  import SigmaInput from '@/components/FormComponents/SigmaInput/SigmaInput.vue';
  import SigmaLink from '@/components/Basic/SigmaLink/SigmaLink.vue';
  import SigmaButton from '@/components/Basic/SigmaButton/SigmaButton.vue';
  import SigmaError from '@/components/Basic/SigmaError/SigmaError.vue';
  import { handleApiError } from '@/services/errorHandler';
  import SigmaSuccess from '@/components/Basic/SigmaSuccess/SigmaSuccess.vue';
  import SigmaLogo from '@/components/Basic/SigmaLogo/SigmaLogo.vue';
  
  export default {
    name: 'RegistrationForm',
    components: {
      SigmaInput,
      SigmaLink,
      SigmaButton,
      SigmaError,
      SigmaSuccess,
      SigmaLogo,
    },
    data() {
      return {
        nickname: '',
        email: '',
        password: '',
        password_confirmation: '',
        error: '',
        success: '',
      };
    },
    methods: {
      async registration() {
        try {
          const response = await apiClient.post('/register', {
            nickname: this.nickname,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation
            
          });
          this.error = '';
          this.success = 'Аккаунт зарегестрирован.';
          console.log(response.data);
        } catch (error) {
            this.success = '';
            this.error = handleApiError(error, 'Ошибка при регистрации. Пожалуйста, проверьте данные и попробуйте снова.');
        }
      },
    },
  };
  </script>
  <style src="./RegistrationForm.css"></style>
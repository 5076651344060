<template>
    <div id="collections-header" :style="{ backgroundColor: color }">
        <img v-if="logo" :src="require('@/assets/images/collections/' + logo)" alt="Logo" class="logo-image" />
        <p v-else class="collections-header_text">{{ text }}</p>
    </div>
</template>
  
  <script>

  export default {
    name: 'CollecitonsHeader',
    props: {
      logo: {
        type: String,
        required: false
      },
      text: {
        type: String,
        required: false
      },
      color: {
        type: String,
        required: false,
        default: '#222222'
      }
    },
    components: {
    }
  };
  </script>
  <style src="./CollectionsHeader.css"></style>
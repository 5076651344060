<template>
    <div class="series" :style="{ backgroundColor: series.color, filter: series.user_has === 0 ? 'grayscale(1)' : 'none'}">
      <div class="series__counter">
        <p>{{ series.user_has }} / {{ series.amount }}</p>
      </div>
      <img :src="require('@/assets/images/series/' + series.logo)" :alt="series.name" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SeriesItem',
    props: {
      series: {
        type: Object,
        required: true
      }
    }
  };
  </script>
    <style src="./SeriesItem.css"></style>
<template>
    <p class="logo">Sigma.Collection</p>
</template>
  
  <script>
  export default {
    name: 'SigmaLogo',
    // props: {
    //   fontSize: {
    //     type: String,
    //     default: 'button'
    //   },
    // },
  };
  </script>
  <style src="./SigmaLogo.css"></style>
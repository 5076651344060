<template>
    <p class="success-message">
        <slot></slot>
    </p>
</template>

<script>
export default {
  name: 'SigmaSuccess',
};
</script>
<style src="./SigmaSuccess.css"></style>
<template>
    <SigmaHeader :email="email"/>
    <main class="user-profile">
      <ProfileHeader :nickname="userName"/>
    </main>
  </template>
  
  <script>
  import SigmaHeader from '@/components/SigmaHeader/SigmaHeader.vue';
  import ProfileHeader from '@/components/Profile/ProfileHeader/ProfileHeader.vue';
  import apiClient from '@/services/api';
  import { handleApiError } from '@/services/errorHandler';

  export default {
    name: 'UserProfile',
    components: {
      SigmaHeader,
      ProfileHeader
    },
    methods: {
      async profile() {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('User is not authenticated');
          }

          const response = await apiClient.get('/profile', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          this.userName = response.data.user.nickname;
          this.email = response.data.user.email;
          console.log('Profile data fetched:', response.data);
        } catch (error) {
          handleApiError(error, 'Failed to load profile data. Please try again.');
          console.error('Profile error:', error);
        }
      },
    },
    created() {
      this.profile();
    },
    data() {
      return {
        userName: '',
      };
    }
  };
  </script>
  
  <style scoped>
  h1 {
    margin-bottom: 20px;
  }
  </style>
<template>
    <header id="header">
        <SigmaLogo />
        <div class="header_right-side">
            <ProfileIcon />
            <p class="email">{{email}}</p>
            <ExitIcon />
        </div>

    </header>
</template>
  
  <script>

  import SigmaLogo from '@/components/Basic/SigmaLogo/SigmaLogo.vue';
  import ProfileIcon from '@/components/Profile/ProfileIcon/ProfileIcon.vue';
  import ExitIcon from '@/components/Profile/ExitIcon/ExitIcon.vue';
  import apiClient from '@/services/api';
  import { handleApiError } from '@/services/errorHandler';

  export default {
    name: 'SigmaHeader',
    components: {
        SigmaLogo,
        ProfileIcon,
        ExitIcon
    },
    methods: {
      async profile() {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('User is not authenticated');
          }

          const response = await apiClient.get('/profile', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          this.email = response.data.user.email;
          console.log('Profile data fetched:', response.data);
        } catch (error) {
          handleApiError(error, 'Failed to load profile data. Please try again.');
          console.error('Profile error:', error);
        }
      },
    },
    created() {
      this.profile();
    },
    data() {
      return {
        email: '',
      };
    }
  };
  </script>
  <style src="./SigmaHeader.css"></style>
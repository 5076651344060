<template>
    <div id="profile-header">
        <div class="profile-header_left-side">
            <img :src="require('@/assets/images/avatar-default.jpg')">
            <div class="profile-header_left-side-data">
                <div>
                    <h1 id="nickname">{{ nickname }}</h1>
                    <p class="level">Новичок</p>
                </div>
                <SigmaLink href="/collections">
                    <SigmaButton type="button">Перейти в коллекцию</SigmaButton>
                </SigmaLink>
            </div>
        </div>
    </div>
</template>
  
  <script>

import SigmaButton from '@/components/Basic/SigmaButton/SigmaButton.vue';
import SigmaLink from '@/components/Basic/SigmaLink/SigmaLink.vue';

  export default {
    name: 'ProfileHeader',
    props: {
      nickname: {
        type: String,
        required: true
      },
    },
    components: {
        SigmaButton,
        SigmaLink
    }
  };
  </script>
  <style src="./ProfileHeader.css"></style>
<template>
    <main id="login-page">
      <LoginForm />
    </main>
  </template>
  
  <script>
  import LoginForm from '@/components/Auth/LoginForm/LoginForm.vue';
  
  export default {
    name: 'LoginPage',
    components: {
      LoginForm,
    }
  }
  </script>
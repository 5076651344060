<template>
    <SigmaHeader/>
    <main class="series-page">
      <CollecitonsHeader :color="color" :logo="logo"/>
      <SeriesList :series="series" />
    </main>
  </template>
  
  <script>
  import SigmaHeader from '@/components/SigmaHeader/SigmaHeader.vue';
  import CollecitonsHeader from '@/components/Collections/CollectionsHeader/CollectionsHeader.vue';
  import SeriesList from '@/components/Collections/SeriesList/SeriesList.vue';
  import apiClient from '@/services/api';
  import { handleApiError } from '@/services/errorHandler';

  export default {
    props: ['id'],
    name: 'SeriesPage',
    components: {
      SigmaHeader,
      CollecitonsHeader,
      SeriesList
    },
    methods: {
      async getCollectionSeries() {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('User is not authenticated');
          }

          const response = await apiClient.get('/collections/' + this.id + '/series', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          this.series =  response.data.collectionSeries;

          console.log('Profile data fetched:', response.data);
        } catch (error) {
          handleApiError(error, 'Failed to load profile data. Please try again.');
          console.error('Profile error:', error);
        }
      },
    },
    created() {
      this.getCollectionSeries();
    },
    data() {
      return {
        color: '#007CEC',
        logo: 'collections-funko.svg',
        series: [],
      };
    }
  };
  </script>
<template>
    <div id="series-list">
        <SeriesItem v-for="oneSeries in series" :key="oneSeries.id" :series="oneSeries" />
    </div>
</template>
  
  <script>

    import SeriesItem from '@/components/Collections/SeriesItem/SeriesItem.vue';

  export default {
    name: 'SeriesList',
    props: {
        series: {
            type: Array,
            required: true
        }
    },
    components: {
        SeriesItem
    }
  };
  </script>
  <style src="./SeriesList.css"></style>
<template>
    <a :href="href" :target="target" class="sigma-link">
      <slot></slot>
    </a>
  </template>
  
  <script>
  export default {
    name: 'SigmaLink',
    props: {
      href: {
        type: String,
        required: true
      },
      target: {
        type: String,
        default: '_self'
      }
    }
  };
  </script>
<style src="./SigmaLink.css"></style>
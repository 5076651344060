<template>
    <p class="error-message">
        <slot></slot>
    </p>
</template>

<script>
export default {
  name: 'SigmaError',
};
</script>
<style src="./SigmaError.css"></style>
export function handleApiError(error, defaultErrorMessage = 'Неизвестная ошибка') {
    let errorMessage = defaultErrorMessage;
  
    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      for (let field in errors) {
        if (errors[field].length > 0) {
          errorMessage = errors[field][0];
          break;
        }
      }
    }
  
    return errorMessage;
}
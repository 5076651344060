import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import RegistrationPage from '@/views/RegistrationPage.vue';
import UserProfile from '@/views/UserProfile.vue';
import CollectionsPage from '@/views/CollectionsPage.vue';
import SeriesPage from '@/views/SeriesPage.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      requiresNotAutht: true,
    },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: RegistrationPage,
    meta: {
      requiresNotAutht: true,
    },
  },
  {
    path: '/collections',
    name: 'CollectionsPage',
    component: CollectionsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/collections/:id/series',
    name: 'SeriesPage',
    component: SeriesPage,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!localStorage.getItem('token');
  const requiresNotAutht = to.matched.some(record => record.meta.requiresNotAutht);

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (requiresNotAutht && isAuthenticated) {
    next('/profile');
  } else {
    next();
  }
});

export default router;